import { styled } from 'styles';

export default styled.div`
  overscroll-behavior: none;

  & {
    height: 100%;
    box-sizing: border-box;
    display: grid;
    position: relative;
    overflow: hidden;

    .listings-map {
      grid-area: map;
    }

    .search-input {
      flex-shrink: 0;
    }

    .header {
      grid-area: header;
      height: 60px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${props => props.theme.colors.borderColor};
      margin: 0 32px 16px 32px;

      & > a,
      & > button {
        font-size: 14px;
        line-height: 24px;
        color: #000;
        text-decoration: none;
        margin-left: 16px;
        cursor: pointer;
        outline: none;
        padding: 0;

        .icon-burger-menu {
          fill: ${props => props.theme.colors.black};
          vertical-align: middle;
        }
        &.logo__link {
          margin: 0;
        }
        &.search__button-search {
          margin: 0;
        }
      }
      &__link {
        display: flex;
        align-items: center;
        position: relative;

        &:first-of-type:after {
          content: '';
          position: absolute;
          width: 1px;
          background: ${props => props.theme.colors.harborGray};
          height: 16px;
          top: 50%;
          transform: translateY(-50%);
          right: -9px;
        }

        .icon {
          margin-right: 4px;
          fill: ${props => props.theme.colors.primary};
        }
      }

      .auth {
        margin-left: 32px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        max-width: 148px;
      }
    }

    .content {
      grid-area: content;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      overflow: hidden;
    }

    .filters-form {
      flex-shrink: 0;
    }

    .search-wrapper {
      display: flex;
      margin: 0 32px 16px 32px;
      flex-shrink: 0;

      .search {
        width: 100%;

        &__results {
          top: 100%;
        }
      }

      .buy-rent-dropdown ~ .search {
        border-left: none;
        border-radius: 0 3px 3px 0;
      }
    }

    .pagination-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 48px 0 58px 0;
      box-sizing: border-box;
      flex-shrink: 0;

      .pagination-info {
        font-size: 10px;
        color: ${props => props.theme.colors.dustyGray};
        margin-bottom: 12px;
      }
    }

    .counter-and-sort {
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 17px;
      flex-shrink: 0;

      .counter {
        margin-right: auto;
      }

      .dropdown {
        width: 220px;

        &__button {
          justify-content: flex-end;
        }

        &__menu {
          width: 160px;
          min-width: unset;
          padding: 4px 12px;
        }
      }
    }
  }

  /* desktop */
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-areas:
      'map header'
      'map content';
    grid-template-columns: 1fr 720px;
    grid-template-rows: auto 1fr;

    .header {
      grid-area: header;
    }

    .basic-filters,
    .form-main,
    .form-bottom,
    .counter-and-sort {
      padding: 0 32px;
    }

    .basic-filters {
      &__save-results-button {
        margin: 0;
      }
    }
  }

  /* tablet */
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-areas:
      'header'
      'content'
      'tabs';
    grid-template-rows: auto 1fr auto;

    .header {
      height: 56px;
      margin: 0 32px 16px 32px;
    }

    .basic-filters,
    .form-main,
    .form-bottom,
    .counter-and-sort,
    .results {
      padding: 0 32px;
    }

    .listings-map {
      margin-bottom: 42px;
    }
  }

  /* mobile */
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-areas:
      'header'
      'content'
      'tabs';
    grid-template-rows: auto 1fr 42px;

    .header,
    .counter-and-sort,
    .basic-filters,
    .form-main,
    .results {
      padding: 0 10px;
    }

    .header {
      height: 56px;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 8px 0;

      .logo {
        margin: 0 24px 0 0;
      }

      .search-wrapper {
        margin: 0;
        width: 100%;
        flex-shrink: 1;

        .search {
          border-left-width: 1px !important;
          border-radius: 3px;
        }
      }

      .menu-button {
        margin-left: 24px;
        width: 32px;
        height: 32px;
      }
    }

    .listings-map {
      margin: 0;
    }

    .results {
      &__cards {
        grid-template-columns: initial !important;

        .property-card {
          width: 100%;
          max-width: initial;
          margin: 0;
        }
      }
    }
    .basic-filters {
      margin-bottom: 16px;
    }
  }
`;
