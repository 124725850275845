import React from 'react';
import pluralize from 'pluralize';

import DropdownMenu, { DropdownOption } from 'components/DropdownMenu';

type QueryParamNumberValue = number | null | undefined;

interface SortDropdownProps {
  sort: string;
  onSortChange: (
    newValue: string | null | undefined,
    updateType?: 'replaceIn' | 'replace' | 'push' | 'pushIn' | undefined
  ) => void;
  paginationData: {
    page: QueryParamNumberValue;
    total_pages: QueryParamNumberValue;
    total_results: QueryParamNumberValue;
  };
}

const sortOptions = [
  { value: 'low_to_high', text: 'Price: Low to High' },
  { value: 'high_to_low', text: 'Price: High to Low' },
  { value: 'sqft', text: 'Square feet' },
  { value: 'recommended', text: 'Recommended' }
];

const CounterAndSort: React.FC<SortDropdownProps> = ({ sort, onSortChange, paginationData }) => {
  return (
    <div className="counter-and-sort">
      <div className="counter" data-cy="listings_counter">
        {paginationData.total_results || 0} {pluralize('home', paginationData.total_results || 0)}
      </div>
      <DropdownMenu
        simple
        placement="bottom-end"
        value={
          <>
            Sort by: <strong>{sortOptions.find(item => item.value === sort)?.text}</strong>
          </>
        }
        render={({ close }) => (
          <>
            {sortOptions.map(item => (
              <DropdownOption
                key={item.value}
                data-cy={`option_${item.value}`}
                active={sort === item.value}
                onClick={() => {
                  onSortChange(item.value, 'replaceIn');
                  close();
                }}>
                {item.text}
              </DropdownOption>
            ))}
          </>
        )}
        className="sort-dropdown"
        data-cy="sort_by_dropdown" />
    </div>
  );
};

export default CounterAndSort;
