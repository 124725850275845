export function getNonEmptyKeys(obj: object): { [key: string]: any } {
  const newObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== null && value !== undefined) newObj[key] = value;
  });
  return newObj;
}

export function formatFiltersQuery(filters: any) {
  return getNonEmptyKeys({
    map_right_bottom_latitude: filters.bounds?.se.lat,
    map_right_bottom_longitude: filters.bounds?.se.lng,
    map_top_left_latitude: filters.bounds?.nw.lat,
    map_top_left_longitude: filters.bounds?.nw.lng,
    bathrooms_min: filters.bathrooms,
    parking_spaces_min: filters.parking_spaces,
    with_images: filters.with_images ? 1 : 0,

    bedrooms_max: filters.beds_max,
    bedrooms_min: filters.beds_min,
    area: filters.area,
    city: filters.city,
    county: filters.county,
    elementary_school: filters.elementary_school,
    high_school: filters.high_school,
    listing_price_max: filters.price_max,
    listing_price_min: filters.price_min,
    listing_status: filters.listing_status,
    listing_type_hr: filters.listing_type_hr,
    middle_or_junior_school: filters.middle_or_junior_school,
    offset: filters.offset,
    places: filters.places,
    postal_code: filters.postal_code,
    search_string: filters.search_string,
    sorty_by: filters.sort,
    sqft_max: filters.sqft_max,
    sqft_min: filters.sqft_min,
    state: filters.state,
    street: filters.street,
    year_built_max: filters.year_built_max,
    year_built_min: filters.year_built_min
  });
}
