import React from 'react';

import IndexLayout from 'layouts';
import { NoSSR } from 'components';
import SearchPage from 'views/SearchPage';

// const SearchPage = React.lazy(() => import('components/SearchPage'));

const Search = () => {
  return (
    <IndexLayout>
      <NoSSR>
        <SearchPage />
      </NoSSR>
    </IndexLayout>
  );
};

export default Search;
