import React, { useState } from 'react';

import { styled } from 'styles';
import { Button } from 'components';
import Map, { MapSkeleton } from 'components/Map';
import { MapData } from '../types';

interface ListingsMapProps {
  mapData: MapData;
  items: ListingsSearchMarker[];
  onSearchThisAreaClick: () => void;
  onMapChange: any;
}

const ListingsMap: React.FC<ListingsMapProps> = ({
  mapData,
  items,
  onSearchThisAreaClick,
  onMapChange
}) => {
  const [mapLoaded, setMapLoaded] = useState(false);

  const handleMapLoad = () => setMapLoaded(true);

  return (
    <StyledListingsMap className="listings-map">
      {mapLoaded ? (
        <Button
          primary
          onClick={onSearchThisAreaClick}
          className="listings-map__search-area"
          data-cy="search_area_button">
          Search this area
        </Button>
      ) : (
        <MapSkeleton />
      )}
      <Map
        zoom={mapData?.zoom}
        center={mapData?.center}
        listings={items}
        onChange={({ center, bounds, zoom }) => onMapChange({ center, bounds, zoom }, 'replaceIn')}
        onLoad={handleMapLoad}
      />
    </StyledListingsMap>
  );
};

export default ListingsMap;

const StyledListingsMap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .listings-map__search-area {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  .map {
    width: 100%;
    height: 100%;
  }
`;
