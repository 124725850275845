import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import pluralize from 'pluralize';
import clsx from 'clsx';

import { styled } from 'styles';
import { formatCurrency } from 'utils/rifmFormatters';
import { useLayout, useDidUpdateEffect } from 'hooks';
import {
  CurrencyInput,
  NumberSelector,
  Input,
  Dropdown,
  DropdownOption,
  Slider,
  Button,
  Icon,
  Checkbox
} from 'components';
import listingTypes from './listingTypes.json';
import listingStatuses from './listingStatuses.json';

interface FiltersFormProps extends React.HTMLAttributes<HTMLFormElement> {
  showMoreFilters: boolean;
  initialValues?: any;
  onSubmit: (values) => void;
  onExpandButtonClick?: () => void;
  onSaveSearchClick?: () => void;
  onCloseRequest: () => void;
}

const sliderMiltiplier = 100000;

const maxYear = new Date().getFullYear();

const FiltersForm: React.FC<FiltersFormProps> = ({
  className,
  showMoreFilters,
  initialValues: initialValuesProp = {},
  onExpandButtonClick,
  onSaveSearchClick,
  onSubmit: onSubmitProp,
  onCloseRequest,
  ...props
}) => {
  const layout = useLayout();
  const initialValues = initialValuesProp;
  if (initialValues)
    Object.entries(initialValues).forEach(([key, value]) => {
      if (value === undefined) delete initialValues[key];
    });

  const defaultValues = {
    bathrooms: 0,
    beds_max: 0,
    beds_min: 0,
    listing_status: [listingStatuses.Active, listingStatuses.UnderContractOrPending],
    listing_type_hr: [],
    parking_spaces: 0,
    price_max: '',
    price_min: '',
    sqft_max: '',
    sqft_min: '',
    year_built_max: '',
    with_images: false
  };

  const { register, control, watch, reset, setValue, handleSubmit } = useForm({
    defaultValues: {
      ...defaultValues,
      ...initialValues
    }
  });

  const filterFormValues = values => {
    const filteredValues = {};
    Object.entries(values).forEach(([key, value]) => {
      if (!value || (Array.isArray(value) && !value.length)) filteredValues[key] = undefined;
      else filteredValues[key] = value;
    });
    return filteredValues;
  };

  const onSubmit = values => {
    const filteredValues = filterFormValues(values);
    onSubmitProp(filteredValues);
    onCloseRequest();
  };

  const formValues = watch(['price_min', 'price_max', 'beds_min', 'beds_max', 'listing_type_hr']);

  const priceMin = Number(formValues.price_min) || 0;
  const priceMax = Number(formValues.price_max) || 0;
  const bedsMin = Number(formValues.beds_min) || 0;
  const bedsMax = Number(formValues.beds_max) || 0;
  const sliderMin = priceMin / sliderMiltiplier;
  const sliderMax = priceMax / sliderMiltiplier;
  let priceText = '';
  const formattedPriceMin = formatCurrency(priceMin);
  const formattedPriceMax = formatCurrency(priceMax);
  if (priceMin && !priceMax) priceText = `${formattedPriceMin}+`;
  else if (priceMax) priceText = `${formattedPriceMin}-${formattedPriceMax}`;
  let bedsText = '';
  if (bedsMin < bedsMax) bedsText = `${bedsMin}-${bedsMax} beds`;
  else if (bedsMin) bedsText = `${bedsMin} ${pluralize('bed', bedsMin)}`;
  const listingType = formValues.listing_type_hr;

  useDidUpdateEffect(() => {
    const filteredValues = filterFormValues({
      price_min: priceMin,
      price_max: priceMax,
      beds_min: bedsMin,
      beds_max: bedsMax,
      listing_type_hr: listingType
    });
    if (onSubmitProp) onSubmitProp(filteredValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceMin, priceMax, bedsMin, bedsMax, listingType]);

  const clearPrice = () => {
    setValue('price_min', undefined);
    setValue('price_max', undefined);
  };

  const priceDropdown = (
    <StyledDropdown
      className={clsx('price', { 'has-price': priceText })}
      data-cy="price_filter_tooltip"
      value={priceText}
      placeholder="Price">
      <h3 className="dropdown__menu__title">Price</h3>
      <div className="input-group">
        {/* <Input name="price_min" ref={register} type="number" before="$" label="Min" min={0} /> */}

        <Controller
          control={control}
          name="price_min"
          defaultValue={null}
          render={controllerProps => (
            <CurrencyInput
              label="Min"
              min={0}
              {...controllerProps}
            />
          )}
        />
        {/* <Input
          name="price_max"
          ref={register}
          type="number"
          before="$"
          label="Max"
          min={priceMin}
        /> */}

        <Controller
          control={control}
          name="price_max"
          defaultValue={null}
          render={controllerProps => (
            <CurrencyInput
              label="Max"
              min={priceMin}
              {...controllerProps}
            />
          )}
        />
      </div>

      <Slider
        value={[sliderMin, sliderMax]}
        defaultValue={[sliderMin, sliderMax]}
        onChange={([min, max]) => {
          setValue('price_min', min * sliderMiltiplier);
          setValue('price_max', max * sliderMiltiplier);
        }}
      />

      <Button type="button" onClick={clearPrice} simple>
        × Clear
      </Button>
    </StyledDropdown>
  );

  const bedsDropdown = (
    <StyledDropdown value={bedsText} placeholder="Beds" className="beds">
      <h3 className="dropdown__menu__title">Beds</h3>
      <Controller
        name="beds_min"
        as={NumberSelector}
        control={control}
        defaultValue={0}
        label="Min"
        min={0}
        max={bedsMax}
      />
      <Controller
        name="beds_max"
        as={NumberSelector}
        control={control}
        defaultValue={0}
        label="Max"
        min={bedsMin}
      />
    </StyledDropdown>
  );

  const showMoreFiltersButton = (
    <StyledButton
      type="button"
      onClick={onExpandButtonClick}
      className="basic-filters__more-filters-button"
      data-cy="more_filters_button"
      outline
      secondary>
      {showMoreFilters ? (
        <>
          <Icon name="minus" size={8} /> Less filters
        </>
      ) : (
          <>
            <Icon name="plus-simple" size={8} /> More filters
          </>
        )}
    </StyledButton>
  );

  const saveSearchButton = (
    <StyledButton
      className="basic-filters__save-search"
      data-cy="save_search_button"
      primary
      outline
      type="button"
      onClick={() => {
        if (onSaveSearchClick) onSaveSearchClick();
      }}>
      Save search
    </StyledButton>
  );

  const onlyWithPhotosCheckbox = (
    <Checkbox ref={register} name="with_images" label="Only with photos" />
  );

  const bathroomsSelector = (
    <Controller
      name="bathrooms"
      as={NumberSelector}
      control={control}
      defaultValue={0}
      label="Bathrooms"
      min={0}
      plusMore
    />
  );

  const parkingSpacesSelector = (
    <Controller
      name="parking_spaces"
      as={NumberSelector}
      control={control}
      defaultValue={0}
      label="Parking Spaces"
      min={0}
      plusMore
    />
  );

  const propertyFactsInputs = (
    <>
      <Input
        name="sqft_min"
        ref={register}
        label="Square Feet"
        placeholder="Min"
        type="number"
        min={0}
      />
      <Input name="sqft_max" ref={register} placeholder="Max" type="number" min={0} />
    </>
  );

  const yearBuiltInputs = (
    <>
      <Input
        name="year_built_min"
        ref={register}
        label="Year Built"
        placeholder="Min"
        type="number"
        min={0}
        max={maxYear}
      />
      <Input name="year_built_max" ref={register} placeholder="Max" type="number" max={maxYear} />
    </>
  );

  const listingTypeDropdown = (
    <Controller
      control={control}
      name="listing_type_hr"
      render={({ onChange, onBlur, value }) => {
        const handleOptionClick = option => {
          onChange(
            Array.isArray(value) && value.includes(option)
              ? value.filter(item => item !== option)
              : [...value, option]
          );
        };

        return (
          <StyledDropdownWithOptions
            className="listing-type"
            data-cy="type_filter"
            value={value.length ? value.join(', ') : 'All types'}
            onBlur={onBlur}>
            {Object.values(listingTypes).map(option => (
              <DropdownOption
                key={option}
                data-cy={`option_${option.replace(/ /g, '_')}`}
                active={value.includes(option)}
                onClick={() => handleOptionClick(option)}>
                {option}
              </DropdownOption>
            ))}
          </StyledDropdownWithOptions>
        );
      }}
    />
  );

  const listingStatusDropdown = (
    <Controller
      control={control}
      name="listing_status"
      render={({ onChange, onBlur, value }) => {
        const handleOptionClick = option => {
          onChange(
            Array.isArray(value) && value.includes(option)
              ? value.filter(item => item !== option)
              : [...value, option]
          );
        };
        return (
          <StyledDropdownWithOptions
            className="listing-status"
            value={
              value.length === 0 || value.length === Object.values(listingStatuses).length
                ? 'Any'
                : value.join(', ')
            }
            label="Listing status"
            onBlur={onBlur}>
            {Object.values(listingStatuses).map(option => (
              <DropdownOption
                key={option}
                active={value.includes(option)}
                onClick={() => handleOptionClick(option)}>
                {option}
              </DropdownOption>
            ))}
          </StyledDropdownWithOptions>
        );
      }}
    />
  );

  const formBottom = (
    <div className="form-bottom">
      <StyledButton primary outline type="reset" onClick={() => reset(defaultValues)}>
        Reset
      </StyledButton>
      <StyledButton primary type="submit">
        Apply filters
      </StyledButton>
    </div>
  );

  const getLayout = () => {
    switch (layout) {
      case 'mobile':
        return (
          <>
            <div className="basic-filters" data-cy="filters_section">
              {showMoreFiltersButton}
              {saveSearchButton}
            </div>
            <StyledMobileFilters className={clsx('form-main mobile', { visible: showMoreFilters })}>
              <div className="mobile-filters__head">
                <h3 className="mobile-filters__title">Filters</h3>
                <StyledButton
                  primary
                  outline
                  type="button"
                  onClick={onExpandButtonClick}
                  className="mobile-filters__button-close">
                  <Icon name="mini-x" size={12} />
                </StyledButton>
              </div>
              <section className="mobile-filters__container">
                <FormSection className="mobile-filters__filter-item" marginRight marginBottom>
                  {priceDropdown}
                </FormSection>
                <FormSection className="mobile-filters__filter-item" marginBottom>
                  {bedsDropdown}
                </FormSection>
              </section>
              <FormSection className="listing-type-section" marginBottom>
                {listingTypeDropdown}
              </FormSection>
              <section className="mobile-filters__container">
                <FormSection className="mobile-filters__filter-item" marginBottom>
                  {onlyWithPhotosCheckbox}
                </FormSection>
              </section>
              <section className="mobile-filters__container">
                <FormSection className="mobile-filters__filter-item" marginRight>
                  {bathroomsSelector}
                </FormSection>
                <FormSection className="mobile-filters__filter-item">
                  {parkingSpacesSelector}
                </FormSection>
              </section>

              <section className="mobile-filters__container mobile-filters__container_property">
                <h4>Property Facts</h4>
                <div className="mobile-filters__wrapper">
                  <FormSection marginBottom marginRight className="mobile-filters__filter-item">
                    {propertyFactsInputs}
                  </FormSection>
                  <FormSection marginBottom className="mobile-filters__filter-item">
                    {yearBuiltInputs}
                  </FormSection>
                </div>
              </section>
              <FormSection>{listingStatusDropdown}</FormSection>

              {formBottom}
            </StyledMobileFilters>
          </>
        );

      default:
        return (
          <>
            <div className="basic-filters" data-cy="filters_section">
              {priceDropdown}
              {bedsDropdown}
              {listingTypeDropdown}
              {showMoreFiltersButton}
              {saveSearchButton}
            </div>
            <div className={clsx('form-main', { visible: showMoreFilters })}>
              <FormSection marginBottom marginRight>
                {onlyWithPhotosCheckbox}
              </FormSection>
              <br />
              <FormSection marginRight>{bathroomsSelector}</FormSection>
              <FormSection>{parkingSpacesSelector}</FormSection>
              <section>
                <h4>Property Facts</h4>
                <FormSection marginBottom marginRight>
                  {propertyFactsInputs}
                </FormSection>
                <br />
                <FormSection marginBottom marginRight>
                  {yearBuiltInputs}
                </FormSection>
              </section>
              <FormSection marginRight>{listingStatusDropdown}</FormSection>
            </div>
            {showMoreFilters && formBottom}
          </>
        );
    }
  };

  return (
    <StyledFiltersForm
      onSubmit={handleSubmit(onSubmit)}
      className={clsx('filters-form', layout, { expand: showMoreFilters }, className)}
      {...props}>
      {getLayout()}
    </StyledFiltersForm>
  );
};

export default FiltersForm;

const StyledFiltersForm = styled.form`
  display: flex;
  flex-direction: column;

  &.expand:not(.mobile) {
    flex: 1;
  }

  h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 24px 0 12px 0;
  }

  h5 {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 12px 0;
  }

  .basic-filters {
    display: flex;
    width: 100%;
    flex-shrink: 0;
    align-items: flex-end;
    margin-bottom: 24px;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      margin-bottom: 8px;
    }

    & > * {
      margin-right: 12px;
    }

    & > button {
      width: 116px;
      height: 36px;
      font-size: 12px;
      line-height: 15px;
      padding: 0 8px;

      &.basic-filters__more-filters-button {
        margin-right: auto;
      }

      &.basic-filters__save-search {
        margin: 0 0 0 12px;
      }
    }
  }

  .form-main {
    display: none;
    margin-bottom: 16px;
    overflow: auto;

    &.visible {
      display: unset;
    }

    &.visible.mobile {
      display: flex;
      z-index: 1001;
    }
  }

  .form-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.colors.cementGray};
    height: 60px;
    margin-top: auto;
  }

  .listing-type .dropdown__menu {
    min-width: 130px;
  }

  .listing-status .dropdown__menu {
    min-width: 190px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  display: flex;
  flex-direction: column;

  &.price .dropdown__menu {
    width: 260px;
  }
  &.has-price .dropdown__text {
    color: ${props => props.theme.colors.black};
  }

  &.beds .dropdown__menu {
    width: 158px;

    .number-selector:first-of-type {
      margin-bottom: 12px;
    }
  }

  &.home-type .dropdown__menu {
    width: 171px;

    .checkboxes {
      display: flex;
      flex-direction: column;

      .checkbox {
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .dropdown__menu {
    padding-left: 12px;
    padding-right: 12px;
  }

  .dropdown__menu__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin: 0 0 16px 0;
  }

  .input-group {
    display: flex;
    margin-bottom: 20px;

    & > * {
      margin-right: 32px;

      &:last-of-type {
        margin-right: initial;
      }
    }
  }

  .slider {
    margin-bottom: 20px;
  }

  .button {
    margin: auto;
  }
`;

const StyledDropdownWithOptions = styled(Dropdown)`
  .dropdown__menu {
    min-width: 130px;
    max-height: 204px;
    overflow: auto;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const StyledButton = styled(Button)`
  height: 36px;
  padding: 0 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  background: none;
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: ${props => props.theme.misc.borderRadius};
  white-space: nowrap;

  .icon {
    margin-right: 8px;
  }
`;

type FormSectionProps = {
  marginRight?: boolean;
  marginBottom?: boolean;
  vertical?: boolean;
};

const FormSection = styled.div<FormSectionProps>`
  display: inline-flex;
  width: 186px;
  align-items: flex-end;
  ${props => props.marginRight && `margin-right: 40px;`}
  ${props => props.marginBottom && `margin-bottom: 24px;`}
  ${props =>
    props.vertical &&
    `
    flex-direction: column;
    align-items: initial;

    .checkbox {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }`}

  & > .input,
  & > .dropdown {
    width: 100%;
    margin-right: 14px;

    &:last-of-type {
      margin-right: initial;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    ${props => props.marginRight && `margin-right: 20px;`}

    &.mobile-filters {
      &__filter-item {
        flex: 1;
        width: auto;
      }
    }

    &.listing-type-section {
      width: calc(50% - 10px);
    }
  }
`;

const StyledMobileFilters = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: white;
  z-index: 1000;
  padding: 16px !important;

  .form-main,
  .form-bottom {
    padding: 16px 10px;
  }

  .form-bottom {
    margin: auto -16px -16px -16px;
  }

  .mobile-filters__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .mobile-filters__title {
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      margin: 0;
    }

    .mobile-filters__button-close {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 36px;

      .icon {
        fill: ${props => props.theme.colors.primary};
        margin: 0;
      }
    }
  }

  .mobile-filters__container {
    display: flex;
    &.mobile-filters__container_property {
      flex-direction: column;
    }
  }

  .mobile-filters__wrapper {
    display: flex;
    align-items: center;
  }
`;
